@tailwind base;
@tailwind components;
@tailwind utilities;

html[data-theme="light"] {
  /* background-color: #f3eee5; */
  /* background-color: #cb6109; */
  color: #5b4c4c;
}

nav {
  background-color: #f3eee5;
}

img {
  pointer-events: none;
}

.mobile {
  @apply flex md:hidden;
}

.desktop {
  @apply hidden md:flex;
}

.img-slide {
  @apply h-auto w-full max-w-[30vw] md:max-h-60 lg:max-h-none 2xl:scale-125;
}

.slide-top-left {
  top: 0;
  left: 0;
  transform: translate(-100%, -100%);
}

.slide-top-right {
  top: 0;
  right: 0;
  transform: translate(100%, -100%);
}

.slide-mid-left {
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
}

.slide-mid-right {
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
}

.slide-bottom-left {
  bottom: 0;
  left: 0;
  transform: translate(-100%, 100%);
}

.slide-bottom-right {
  bottom: 0;
  right: 0;
  transform: translate(100%, 100%);
}

.slide-top-left.visible,
.slide-top-right.visible,
.slide-bottom-left.visible,
.slide-bottom-right.visible {
  transform: translate(0, 0);
  transition: transform 2s ease;
  --skewStart: 0deg;
  --skewEnd: 5deg;
  animation: custom-skew 8s ease-in-out 2s infinite;
}

.slide-mid-left.visible,
.slide-mid-right.visible {
  transform: translate(0, -50%);
  transition: transform 2s ease;
}

.fade-in {
  opacity: 0;
}

.fade-in {
  opacity: 1;
  transition: opacity 2s ease;
}

@keyframes custom-skew {
  0% {
    transform: skew(var(--skewStart, --skewStart));
  }
  50% {
    transform: skew(var(--skewEnd, --skewEnd));
  }
  to {
    transform: skew(var(--skewStart, --skewStart));
  }
}

.accordion {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
}

.accordion-wrapper[data-active="false"] {
  @apply opacity-0;
  transition: all 0.6s ease-in-out;
}

.accordion-wrapper[data-active="true"] {
  @apply bg-white opacity-100;
  transition: all 0.6s ease-in-out;
}

.menu-primary {
  @apply cursor-pointer text-menu-primary-100 hover:text-menu-primary-200 active:text-menu-primary-300;
}

.menu-secondary {
  @apply cursor-pointer text-menu-secondary-100 hover:text-menu-secondary-200 active:text-menu-secondary-300;
}

.orange-gradient[data-id="yo-soy"] {
  background: rgb(253, 140, 7);
  background: radial-gradient(
    circle,
    rgba(253, 140, 7, 1) 0%,
    rgba(0, 0, 0, 0) 60%
  );
}

.yellow-gradient[data-id="yo-soy"] {
  background: rgb(253, 140, 7);
  background: radial-gradient(
    circle,
    rgba(254, 199, 6, 1) 0%,
    rgba(0, 0, 0, 0) 60%
  );
}

/* https://www.cssportal.com/style-input-range/ */

input[type="range"] {
  height: 23px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background-color: transparent;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #00000055;
  border-radius: 50px;
  border: 0px solid #000000;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 2px #000000;
  border: 0px solid #2497e3;
  height: 16px;
  width: 16px;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #00000055;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #00000055;
  border-radius: 50px;
  border: 0px solid #000000;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 2px #000000;
  border: 0px solid #2497e3;
  height: 16px;
  width: 16px;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #00000055;
  border: 0px solid #000000;
  border-radius: 100px;
  box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-fill-upper {
  background: #00000055;
  border: 0px solid #000000;
  border-radius: 100px;
  box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 2px #000000;
  border: 0px solid #2497e3;
  height: 16px;
  width: 16px;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #00000055;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #00000055;
}
